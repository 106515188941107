import React from 'react'
import Seo from '../components/Seo'

export default function Header() {
  return (
    <div>
      <Seo title="404 - sorry, couldn't find that..." />
      404. Not Found
    </div>
  )
}
